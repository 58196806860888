























import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Settings extends Vue {
  public settings: any[] = []
  public settingsTags: any[] =[]

  mounted () {
    this.$store.dispatch('getAppSettings').then((response) => {
      this.settings = response.body
      let settings = response.body
      this.settingsTags = settings.reduce((h:any, a:any) => Object.assign(h, { [a.tag]: (h[a.tag] || []).concat(a) }), {})
    })
  }

  getSettingPropIndex (k:any) {
    let index = this.settings.findIndex(x => x.key === k)
    return index
  }

  updateSettings () {
    this.$validator.validateAll('update-settings').then((result) => {
      if (result) {
        this.$store.commit('showLoading')
        this.$store.dispatch('setAppSettings', this.settings).then((response) => {
          this.$store.dispatch('getAppSettingsKeyValuePair')
          this.$store.commit('showMessage', { message: 'Saved!' })
        }, (response) => this.$notify({ type: 'error', text: 'Unable to save settings' }))
      } else {
        this.$notify({ type: 'error', text: 'All Setting fields are required and should be numeric or decimal upto 1 decimal & max task per pickl should be 5 or less' })
      }
    })
  }
}
