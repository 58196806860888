var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box white-bg in-padd" }, [
    _vm.settings.length > 0
      ? _c(
          "form",
          {
            attrs: { "data-vv-scope": "update-settings" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.updateSettings($event)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.settingsTags, function(value, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "col-xs-12 col-sm-6" },
                  [
                    _c("h3", { staticClass: "text-center mb30 mt0" }, [
                      _vm._v(_vm._s(key))
                    ]),
                    _vm._l(value, function(setting) {
                      return _c(
                        "div",
                        { key: setting.key, staticClass: "posRel" },
                        [
                          _vm.settings[_vm.getSettingPropIndex(setting.key)]
                            .key == "max_tasks_per_pickl"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.settings[
                                        _vm.getSettingPropIndex(setting.key)
                                      ].value,
                                    expression:
                                      "settings[getSettingPropIndex(setting.key)].value"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|integer|max_value:5",
                                    expression: "'required|integer|max_value:5'"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  name:
                                    _vm.settings[
                                      _vm.getSettingPropIndex(setting.key)
                                    ].key
                                },
                                domProps: {
                                  value:
                                    _vm.settings[
                                      _vm.getSettingPropIndex(setting.key)
                                    ].value
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.settings[
                                        _vm.getSettingPropIndex(setting.key)
                                      ],
                                      "value",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.settings[
                                        _vm.getSettingPropIndex(setting.key)
                                      ].value,
                                    expression:
                                      "settings[getSettingPropIndex(setting.key)].value"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|decimal:1",
                                    expression: "'required|decimal:1'"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  name:
                                    _vm.settings[
                                      _vm.getSettingPropIndex(setting.key)
                                    ].key
                                },
                                domProps: {
                                  value:
                                    _vm.settings[
                                      _vm.getSettingPropIndex(setting.key)
                                    ].value
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.settings[
                                        _vm.getSettingPropIndex(setting.key)
                                      ],
                                      "value",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c(
                            "label",
                            { staticClass: "vertical-center-transform" },
                            [
                              _vm._v(_vm._s(setting.label) + " ("),
                              _c("small", [_vm._v(_vm._s(setting.unit))]),
                              _vm._v(")")
                            ]
                          ),
                          _c("div", { staticClass: "clearfix" }),
                          _c("hr")
                        ]
                      )
                    })
                  ],
                  2
                )
              }),
              0
            ),
            _c("div", { staticClass: "clearfix" }),
            _vm._m(0)
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-right" }, [
      _c("input", {
        staticClass: "btn-lg-green",
        attrs: { type: "submit", value: "SUBMIT" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }